"use strict";
import mobileMenu from "./modules/mobile-menu";
import localStorage from './modules/localStorage';

// import smoothScroll from './libs/SmoothScroll.min';
window.addEventListener("DOMContentLoaded", () => {
 // document.querySelector('.page-transition').style.opacity = 0;
  mobileMenu();
  localStorage();

SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 700,
    // Размер шага в пикселях
    stepSize: 100,

    // Дополнительные настройки:

    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 1,

    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 50,

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,

    // Поддержка тачпада
    touchpadSupport: true,
  });

document.querySelectorAll('option').forEach(item => item.style.outline = 'none')
const body = document.querySelector("body"),
      cursor = document.getElementById("cursor"),
      follower = document.getElementById("aura"),
      links = document.querySelectorAll("a, input, button")

  let mouseX = -100, mouseY = 0, posX = -100, posY = 0

  body.addEventListener("mousemove", e => {
    mouseCoords(e);
    cursor.classList.remove("hidden")
    follower.classList.remove("hidden")
  })

  body.addEventListener("mouseout", () => {
    cursor.classList.add("hidden")
    follower.classList.add("hidden")
  })

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("mouseover", () => {
      cursor.classList.add("active")
      follower.classList.add("active")
    })

    links[i].addEventListener("mouseout", () => {
      cursor.classList.remove("active")
      follower.classList.remove("active")
    })
  }

  function mouseCoords(e) {
    mouseX = e.pageX
    mouseY = e.pageY
  }

  gsap.to({}, .01, {
    repeat: -1,
    onRepeat: () => {

      posX += (mouseX - posX) / 5
      posY += (mouseY - posY) / 5

      gsap.set(cursor, {
        css: {
          left: mouseX,
          top: mouseY
        }
      });

      gsap.set(follower, {
        css: {
          left: posX - 24,
          top: posY - 24
        }
      })
    }
  })

  gsap.registerPlugin(ScrollTrigger);
  
  let mm = gsap.matchMedia();

  mm.add("(min-width: 992px", () => {
    
    const tlShowHeader = gsap.timeline({
      defaults: { // children inherit these defaults
        duration: 1.5,
        ease: "power4.out"
      },
      onComplete: () => {
        // gsap.set("body", { overflow: "auto"})
        // ScrollTrigger.refresh();
      },
    });

    if(window.innerWidth > 991) {
      tlShowHeader
      .from(".menu__logo svg", { opacity: 0, y: -150})
      .from(".menu__local", { opacity: 0, y: -50, }, "<20%")
      .from(".menu__item", { opacity: 0, y: -50, stagger: 0.2 }, "<20%")
      .from(".menu__telephone", { opacity: 0, y: -50, stagger: 0.2, delay: .2 }, "<20%")
      .from(".menu__btn", { opacity: 0, y: -50, delay: .2}, "<")

    }
    if (window.innerWidth <= 991) {
      tlShowHeader
      .from(".menu__logo svg", { opacity: 0, y: -150})
      // .from(".menu__logo", { opacity: 0, duration: 1 })
      // .from(".menu__logo", { top: "25vh", width: "50vw", height: "50vh", delay: 0.5 })
      // .from("main", { opacity: 0, y: 50}, "<")
      .from(".menu__local", { opacity: 0, y: -50, }, "<30%")
      .from(".menu__arrow", { opacity: 0, y: -50, }, "<30%")
      .from(".menu__item", { opacity: 0, y: -50, stagger: 0.2 }, "<30%")
    }
  })

    gsap.registerEffect({
      name: "showText",
      effect: (targets, trigger) => {
        return gsap.from(targets, {
          scrollTrigger: {
            trigger: trigger,
            start: "top bottom",
            toggleActions: "play none none reverse",
          },
          ease: "power4.out",
          duration: 1,
          y: 50,
          opacity: 0,
          stagger: 0.1
        });
      },
      extendTimeline: true
    });
  
    gsap.utils.toArray(".fade-in").forEach((target, i) => {
      gsap.from(target, {
        scrollTrigger: {
          trigger: target,
          start: "bottom bottom",
          toggleActions: "play none none reverse",
        },
        opacity: 0,
        y: 50,
      })  
    });
  
});
