function localStorage () {
    const eng = document.querySelector('#eng');
    const ru = document.querySelector('#ru');
    const engContent = document.querySelectorAll('.en');
    const ruContent = document.querySelectorAll('.ru');
    eng.classList.remove('menu__lang--active');
    ru.classList.remove('menu__lang--active');
    engContent.forEach(item => item.style.display = 'none');
    ruContent.forEach(item => item.style.display = 'none');
    if(window.localStorage.length === 0) {
        window.localStorage.setItem('eng', true);
    }
    switch (window.localStorage.getItem('eng')) {
        case 'true':
            eng.classList.add('menu__lang--active');
            engContent.forEach(item => item.style.display = 'block');
            break;
        case 'false':
            ru.classList.add('menu__lang--active');
            ruContent.forEach(item => item.style.display = 'block');
            break;
        
    }
    ru.addEventListener('click', () => {
        window.localStorage.setItem('eng', false);
        eng.classList.remove('menu__lang--active');
        ru.classList.add('menu__lang--active');
        engContent.forEach(item => item.style.display = 'none');
        ruContent.forEach(item => item.style.display = 'block');
    });
    eng.addEventListener('click', () => {
        window.localStorage.setItem('eng', true);
        eng.classList.add('menu__lang--active');
        ru.classList.remove('menu__lang--active');
        ruContent.forEach(item => item.style.display = 'none');
        engContent.forEach(item => item.style.display = 'block');
    })
    if(window.innerWidth <= 991) {
        document.querySelectorAll('.button--header').forEach(item => item.style.display = 'none');
        const arrow = document.querySelector('.menu__arrow');
        const langs = document.querySelectorAll('.menu__lang');
        const activeLang = document.querySelector('.menu__lang--active');
        const arrowDown = arrow.firstChild;
        const arrowUp = arrow.lastChild;
        const langWrapper = document.querySelector('.menu__wrap');

        
        langs.forEach(item => {
            item.addEventListener('click', (event) => {
                langs.forEach(item => {
                    item.style.visibility = 'hidden';
                    item.style.opacity = 0;
                    item.style.pointerEvents = 'none'});
                event.target.style.visibility = 'visible';
                event.target.style.opacity = 1;
                arrowDown.classList.remove('open');
                arrowUp.classList.add('open');
                
            })
        })

        const onBodyClick = () => {
            if(!arrowUp.classList.contains('open')) {
                document.addEventListener('click', (event) => {
                   if(!event.target.classList.contains('menu__lang') || !event.target.parentNode.classList.contains('arrUp') || !event.target.  classList.contains('arrUp')) {
                        console.log('hihihihi')
                    }
                })
            }
            
        }


        function openLanguages (trigger) {
            trigger.addEventListener('click', () => {
                langs.forEach(item => {
                    arrowDown.classList.add('open');
                    arrowUp.classList.remove('open');
                    item.style.visibility = 'visible';
                    item.style.opacity = 1;
                    item.style.pointerEvents = 'auto';
                });
            });
            document.addEventListener('click', onBodyClick);
            
        }
        
        openLanguages(arrow);
        
        function closeLanguages () {
            document.addEventListener('click', (event) => {
                if(event.target.parentNode.classList.contains('arrUp') || event.target.classList.contains('arrUp')) {
                    arrowUp.classList.add('open');
                    arrowDown.classList.remove('open');
                    langs.forEach(item => {
                        item.style.visibility = 'hidden';
                        item.style.opacity = 0;
                        item.style.pointerEvents = 'none'});
                    activeLang.style.visibility = 'visible';
                    activeLang.style.opacity = 1;
                }
            })
           // document.removeEventListener('click', onBodyClick);
        }
        closeLanguages ();
    }
    
}

export default localStorage;