function mobileMenu () {
    const headerButton = document.querySelector('.button') 
        if (window.screen.width <= 991) {
            headerButton.textContent = "Contact";
        }

    function toggleMobileMenu(trigger) {
        const menuBtn = document.querySelector('.menu__mobile');
        const close = document.querySelector('.menu-mobile__close');
        const mobileMenu = document.querySelector('.menu-mobile');

        menuBtn.addEventListener(trigger, () => {
            mobileMenu.classList.add('active');
        });
        close.addEventListener(trigger, () => {
            mobileMenu.classList.remove('active');
        })
    }
    
    toggleMobileMenu('click');
    toggleMobileMenu('touched');
}
module.exports = mobileMenu;